import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import InitiativeApplication from "../../components/career/initiative-application/initiative-application"
import JobOfferTypes from "../../components/career/job-offer-types/job-offer-types"
import MunzingAsEmployerHeader from "../../components/career/munzing-as-employer-header/munzing-as-employer-header"
import Testimonials from "../../components/career/testimonials/testimonials"
import TextWithImage from "../../components/career/text-with-image/text-with-image"
import WhatAreAdditives from "../../components/career/what-are-additives/what-are-additives"
import TextWithOrWithoutGallery from "../../components/dynamic-page-components/text-with-or-without-gallery/text-with-or-without-gallery"
import { GalleryPictureItem } from "../../components/gallery/model/gallery-item"
import BrandPreview from "../../components/generic-preview/brand-preview/brand-preview"
import GenericPreview from "../../components/generic-preview/generic-preview"
import IconExpansionSection from "../../components/icon-expansion-section/icon-expansion-section"
import OverviewSlider from "../../components/overview-slider/overview-slider"
import PageHeader from "../../components/page-header/page-header"
import TextWithImageHero from "../../components/page-heros/text-with-image-hero"
import RichText from "../../components/rich-text/rich-text"
import SEO from "../../components/seo"
import FullWidthTileContent from "../../components/tile/full-width-tile-content/full-width-tile-content"
import Tile from "../../components/tile/tile"
import TileContentSpacer from "../../components/tile/tile-content-spacer/tile-content-spacer"
import SideNavView from "../../components/views/side-nav-view/side-nav-view"
import useNav from "../../hooks/useNav"
import { removeLocaleFromUri } from "../../i18n/removeLocaleFromUri"
import { useTranslation } from "../../i18n/translate"
import {
  dynamicImageWithGatsbyImage,
  dynamicImageWithPublicUrl,
} from "../../util/dynamic-image"

const SustainabilityPage = ({ data, location }) => {
  const { t } = useTranslation()

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("SUSTAINABILITY-SOCIAL-PAGE_TITLE")}
        descriptionTranslatedString={t(
          "SUSTAINABILITY-SOCIAL-META_DESCRIPTION",
        )}
      />
      <SideNavView
        titleTranslatedString={t("SUSTAINABILITY-SOCIAL-PAGE_TITLE")}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <Tile tileSize={"m"}>
              <TextWithOrWithoutGallery
                gallerySize={"large"}
                centerText={false}
                gallery={[]}
                textTranslationKey={"SUSTAINABILITY-SOCIAL-TILE1-DESCRIPTION"}
              ></TextWithOrWithoutGallery>
            </Tile>
          </div>

          <div className="_fp-col-6">
            <Tile
              tileSize={"m"}
              titleTranslatedString={t("SUSTAINABILITY-SOCIAL-TILE2-TITLE")}
            >
              <FullWidthTileContent>
                <GatsbyImage
                  sizes={
                    "(max-width: 600px) 100vw, (min-width: 601px) and (max-width: 900px) 50vw, 33vw"
                  }
                  image={dynamicImageWithGatsbyImage(data.local_communities)}
                  alt={t("SUSTAINABILITY-SOCIAL-TILE2-TITLE")}
                />
              </FullWidthTileContent>
              <TileContentSpacer></TileContentSpacer>
              <RichText
                textTranslationKey={"SUSTAINABILITY-SOCIAL-TILE2-DESCRIPTION"}
              ></RichText>
            </Tile>
          </div>

          <div className="_fp-col-6">
            <Tile
              tileSize={"m"}
              titleTranslatedString={t("SUSTAINABILITY-SOCIAL-TILE3-TITLE")}
            >
              <FullWidthTileContent>
                <GatsbyImage
                  sizes={
                    "(max-width: 600px) 100vw, (min-width: 601px) and (max-width: 900px) 50vw, 33vw"
                  }
                  image={dynamicImageWithGatsbyImage(data.occupational_safety)}
                  alt={t("SUSTAINABILITY-SOCIAL-TILE3-TITLE")}
                />
              </FullWidthTileContent>
              <TileContentSpacer></TileContentSpacer>
              <RichText
                textTranslationKey={"SUSTAINABILITY-SOCIAL-TILE3-DESCRIPTION"}
              ></RichText>
            </Tile>
          </div>
        </div>
      </SideNavView>
    </>
  )
}

export default SustainabilityPage

export const query = graphql`
  {
    hero: strapiDynamicImage(uid: { eq: "SUSTAINABILITY_SOCIAL_PAGE_IMAGE" }) {
      ...DynamicImageWithGatsbyImage
    }
    local_communities: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_SOCIAL_LOCAL_COMMUNITIES_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    occupational_safety: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_SOCIAL_OCCUPATIONAL_SAFETY_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
